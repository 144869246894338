import React from 'react';
import { Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { Summary } from '../types';

interface CallSummaryProps {
  summary: Summary | null;
}


const CallSummary: React.FC<CallSummaryProps> = ({ summary }) => {
  if (!summary) {
    return (
      <Paper elevation={3} sx={{ p: 2, mb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
      <Typography variant="h6" gutterBottom>Call Summary</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Total Calls: {summary.totalCalls}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Completed Calls: {summary.completedCalls}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Incomplete Calls: {summary.incompleteCalls}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Avg Duration: {summary.avgDurationMinutes.toFixed(2)} min</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Total Duration: {summary.totalDurationMinutes.toFixed(2)} min</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Avg Cost: ${summary.avgCost.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Total Cost: ${summary.totalCost.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Avg OpenAI Cost: ${summary.avgOpenAICost.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2">Total OpenAI Cost: ${summary.totalOpenAICost.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CallSummary;