import axios from 'axios';
import { Call } from './types/Call';
import { Summary } from './types';
import { getCallById as apiGetCallById, getCalls as apiGetCalls } from './api/retellApi';

const API_URL = process.env.REACT_APP_BACKEND_URL || '/api';

interface CallsResponse {
  calls: Call[];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

class DataManager {
  private calls: Call[] = [];
  private userEmail: string | null = null;
  private isSuperuser: boolean = false;
  private currentPage: number = 1;
  private limit: number = 50;
  private totalPages: number = 1;

  public setUserEmail(email: string) {
    this.userEmail = email;
    localStorage.setItem('userEmail', email);
  }

  public getUserEmail(): string | null {
    if (!this.userEmail) {
      this.userEmail = localStorage.getItem('userEmail');
    }
    return this.userEmail;
  }

  public setIsSuperuser(isSuperuser: boolean) {
    this.isSuperuser = isSuperuser;
  }

  public getIsSuperuser(): boolean {
    return this.isSuperuser;
  }

  private getHeaders() {
    return {
      'user-email': this.userEmail || '',
    };
  }

  public async getCallById(callId: string): Promise<Call | null> {
    if (!this.userEmail) {
      console.error('User email not set');
      return null;
    }

    try {
      return await apiGetCallById(callId);
    } catch (error) {
      console.error('Error fetching call:', error);
      return null;
    }
  }

  public async getCalls(page: number = 1, searchTerm: string = ''): Promise<CallsResponse> {
    if (!this.userEmail) {
      console.error('User email not set');
      return { calls: [], total: 0, page: 1, limit: this.limit, totalPages: 1 };
    }
  
    try {
      const calls = await apiGetCalls();
      // Filter calls if search term is provided
      const filteredCalls = searchTerm
        ? calls.filter(call => 
            call.call_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            call.agent_id?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : calls;

      // Calculate pagination
      const total = filteredCalls.length;
      const totalPages = Math.ceil(total / this.limit);
      const startIndex = (page - 1) * this.limit;
      const endIndex = startIndex + this.limit;
      const paginatedCalls = filteredCalls.slice(startIndex, endIndex);

      this.calls = paginatedCalls;
      this.currentPage = page;
      this.totalPages = totalPages;

      return {
        calls: paginatedCalls,
        total,
        page,
        limit: this.limit,
        totalPages
      };
    } catch (error) {
      console.error('Error fetching calls:', error);
      return { calls: [], total: 0, page: 1, limit: this.limit, totalPages: 1 };
    }
  }

  public async getCallSummary(): Promise<Summary | null> {
    if (!this.userEmail) {
      console.error('User email not set');
      return null;
    }

    try {
      const response = await axios.get(`${API_URL}/call-summary`, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching call summary:', error);
      return null;
    }
  }

  public async getAgentAnalytics(): Promise<any[]> {
    if (!this.userEmail) {
      console.error('User email not set');
      return [];
    }

    try {
      const response = await axios.get(`${API_URL}/agent-analytics`, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching agent analytics:', error);
      return [];
    }
  }

  public async forceUpdate(): Promise<void> {
    if (!this.userEmail) {
      console.error('User email not set');
      return;
    }

    try {
      await axios.post(`${API_URL}/update-calls`, {}, {
        headers: this.getHeaders(),
      });
      await this.getCalls(1); // Reset to first page after update
    } catch (error) {
      console.error('Error updating calls:', error);
    }
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  public getTotalPages(): number {
    return this.totalPages;
  }

  public getLimit(): number {
    return this.limit;
  }
}

export const dataManager = new DataManager();
