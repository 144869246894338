import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

interface ExpandedChartProps {
  open: boolean;
  onClose: () => void;
  data: any[];
  dataKey: string;
  title: string;
  color: string;
}

const CustomXAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text 
        x={0} 
        y={0} 
        dy={16} 
        textAnchor="end" 
        fill="#666" 
        transform="rotate(-45)"
        style={{ fontSize: '12px' }}
      >
        {payload.value}
      </text>
    </g>
  );
};

const ExpandedChart: React.FC<ExpandedChartProps> = ({ open, onClose, data, dataKey, title, color }) => {
  const theme = useTheme();

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ResponsiveContainer width="100%" height="80%">
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 70 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="agentId" 
              height={60} 
              tick={<CustomXAxisTick />}
            >
              <Label value="Agent ID" position="insideBottom" offset={-10} />
            </XAxis>
            <YAxis>
              <Label value={title} angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
            </YAxis>
            <Tooltip 
              contentStyle={{ backgroundColor: theme.palette.background.paper }}
              itemStyle={{ color: theme.palette.text.primary }}
            />
            <Legend />
            <Bar dataKey={dataKey} fill={color} />
          </BarChart>
        </ResponsiveContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandedChart;